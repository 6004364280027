@font-face {
    font-family: 'Work Sans', Helvectia, Arial, sans-serif;
    src: url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;300;400;500;700&display=swap);
}

@font-face {
    font-family: 'Roboto Mono', Courier, monospace;
    src: url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;400;500&display=swap);
}

