@import "normalize";
@import "fonts";
@import "variables";

* {
    box-sizing: border-box;
    // background: purple;
  }
  
  ::selection {
    background-color: $prime;
    color: $white;
  }
  
    html {
    font-family: $font1;
    font-size: 100%;
    background-color: $white;
     }

     body {
      color: $text-grey;
     }
  
  #hide {
    display: none;
  }
  
  .logo {
    width: 4em;
    height: 4em;
    float: left;
    margin-left: 20px;
    
  }
  
  .gradient {
  background-image: $gradient;
    }
  
  .gradient.contact {
    height: 55vh;
  }
  
  .index-hero {
    max-width: 80%;
    
  }
  
  
  .grid {
    max-width: 960px;
    margin: 30px auto 100px auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 40px;
  }

  .hero {
    max-width: 700px;
    margin: 120px auto 0px auto;
  }
  
  .hero img {
    @include dropshadow($trans-blue);
    width: 100%;
    border-radius: 5px;
    
  }
  
  .circ-art {
    display: flex;
    height: 0vh;
    align-items: center;
    justify-content: center;
    animation-name: group;
    animation-duration: $duration;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform: translatey(-120px) rotate(0deg);
  }
  
  @keyframes group {
    100% {
      transform: translatey(-120px) rotate(360deg);
    }
  }
  
  .circle1 {
    width: 70px;
    height: 70px;
    opacity: 0.5;
    transform: rotate(0deg);
    border-radius: 100%;
    background-color: $trans-blue;
    animation-name: cir1;
    animation-duration: $duration;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform-origin: center 0px;
  }
  
  /* @keyframes cir1 {
    100% {
      transform: rotate(360deg);
    }
  } */
  
  .circle2 {
    width: 40px;
    height: 40px;
    transform: translate(10px, 10px) rotate(0deg);
    border-radius: 100%;
    background-color: $trans-blue;
    opacity: 0.6;
    animation-name: cir2;
    animation-duration: $duration;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform-origin: center 0px;
  }
  
  @keyframes cir2 {
    100% {
      transform: translate(10px, 10px) rotate(360deg);
    }
  }
  
  .circle3 {
    width: 15px;
    height: 15px;
    transform: translate(10px, -75px) rotate(0deg);
    border-radius: 100%;
    opacity: 0.3;
    background-color: $trans-blue;
    animation-name: cir3;
    animation-duration: $duration;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform-origin: center 15px;
  }
  
  @keyframes cir3 {
    100% {
      transform: translate(10px, -75px) rotate(360deg);
    }
  }
  
  .circle4 {
    width: 10px;
    height: 10px;
    transform: translate(10px, -35px) rotate(0deg);
    border-radius: 100%;
    background-color: $trans-blue;
    opacity: 0.6;
    animation-name: cir4;
    animation-duration: $duration;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform-origin: center 10px;
  }
  
  // @keyframes cir4 {
  //   100% {
  //     transform: translate(10px, -35px) rotate(360deg);
  //   }
  // }
  
  .circle5 {
    width: 5px;
    height: 5px;
    transform: translate(0px, -10px) rotate(0deg);
    border-radius: 100%;
    background-color: $trans-blue;
    opacity: 0.5;
    animation-name: cir5;
    animation-duration: $duration;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform-origin: left -20px;
  }
  
  @keyframes cir5 {
    100% {
      transform: translate(0px, -10px) rotate(360deg);
    }
  }
  
  
  .bold {
    font-family: $font2;
    font-weight: 400;
    color: $grey-blue;
    font-size: 13px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
  }
  
  .index-hero {
    margin: 100px auto 80px auto;
    padding-top: 150px;
    padding-bottom: 200px;
  }
  
  .index-hero h1 {
    text-align: center;
    font-weight: 300;
    font-size: 42px;
    max-width: 850px;
    margin: 0 auto;
  }
  
  .highlight {
    color: $prime;
  }
  
  h2 {
    font-size: 24px;
    font-weight: 300;
    color: $white;
    display: inline-block;
    background-color: $grey;
    margin: 40px 0px 6px 0px;
    padding: 6px 18px;
    box-shadow: 0 10px 20px 0 rgba(167, 185, 218, 0.3);
    letter-spacing: 0.5px;
    width: 100%;
    text-align: center;
    border-radius: 5px;
  }
  
  h4 {
    font-family: 'Roboto Mono', Courier, monospace;
    font-weight: 400;
    color: $grey;
    text-align: center;
    text-transform: uppercase;
  }
  
  .gallery-mid {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 100px;
    max-width: 1180px;
    margin: 0 auto;
     }
  
  .gallery-mid2 {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 100px;
    margin: 0 auto;
    max-width: 990px;
  }

  .imgCapt img {
    float: left;
    max-width: 450px;
    margin-bottom: 8px;
    box-shadow: 0 10px 20px 0 rgba(167, 185, 218, 0.3);
    border-radius: 5px;
     }

  .vert img {
      width: 190px;
        
      }
  
  
  .imgCapt {
    margin: 20px 30px;
  }
  
  .box {
    width: 500px;
    height: 300px;
    float: left;
    background-color: $text-grey;
    margin-right: 20px;
    margin-top: -20px;
    box-shadow: 0 10px 20px 0 rgba(167, 185, 218, 0.3);
  }
  
  .fullbleed {
    background: $grey;
    padding: 100px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
   
  }

  #fullbleed2 {
    background-image: $gradient3;
  }
    
  .fullbleed img {
    max-height: none;
    margin: 0 20px;
    box-shadow: $shadow2;
    width: 100%;
    max-width: 800px;
    border-radius: 5px;
   
  }

  #dropshadow {
    box-shadow: $shadow2;
  }
  
  figcaption {
    font-size: 12px;
      
  }
  
  .figWhite {
    color: $white;
    margin-left: 20px;
    }
  
  .text {
    max-width: 500px;
    width: 50vw;
    display: block;
    margin: 40px auto;
  }
  
  p a:link {
    color: $text-grey;
   text-decoration: underline;
      
  }
  
  p a:hover {
    /* color: $grey-blue; */
    color: #86BFA8;
    text-decoration: none;
      }
  
  p a:visited {
    color: $text-grey;
  }
  
  p a:visited:hover {
    color: #86BFA8;
  }
  
  
  h5 {
    font-family: $font2, Courier, monospace;
    font-weight: 400;
    color: $white;
    font-size: 13px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
  }
    
  .items {
    overflow: hidden;
    width: 300px;
    height: 200px;
    text-align: center;
    margin: 10px;
    background-color: $grey-blue;
    box-shadow: 0 10px 20px 0 rgba(167, 185, 218, 0.3);
    border-radius: 5px;
  }
  
  .items h3 {
    opacity: 0;
    position: absolute;
     
  }
  
  
  .items:hover h3 {
    opacity: 0;
    transition: opacity 0.2s;
    font-family: $font2;
    font-weight: 800;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 16px;
    margin: 100px 20px;
    color: $white;
  }
  
  .items img {
    width: 100%;
    transition:  opacity 0.8s, transform 0.4s ease-in-out;
  }
  
  .items img:hover {
    opacity: .4;
    transform: scale(1.05);
  } 
  .nav {
    list-style: none;
    margin: 20px 20px;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
  }
  
  .nav a {
    text-decoration: none;
    display: inline-block;
    margin: 1em 1em .3em 1em;
    color: $grey;
    font-family: $font1;
  }
  
  .selected a {
    border-bottom: 2px solid $prime;
    padding-bottom: 3px;
  }
  
  .nav a:hover {
    border-bottom: 2px solid #505050;
    padding-bottom: 3px;
    transition: .3s;
  }
  
.cta {
  width: 100%;
  background-image: $gradient4;
  height: 12rem;
  overflow: hidden;
  margin: 0 17% 0 auto;
  box-shadow: $shadow1;
}

.cta-text {
  position: relative;
  z-index: 1;
  max-width: 500px;
  width: 50vw;
  display: block;
  margin: 40px auto 40px 60%;
}

.cta h6 {
  font-size: 1.5em;
  color: $text-grey;
  font-weight: 300;
  margin: 1rem 0 0.7rem 0;
  }

  .group2 {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: center;
    animation-name: group2;
    animation-duration: $group-dur;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform: translate(-40px,80px) rotate(0deg);
  }
  
  @keyframes group2 {
    100% {
      transform: translate(-40px,80px) rotate(360deg);
    }
  }
  
  .dot1 {
    width: 3rem;
    height: 3rem;
    opacity: $trans1;
    border-radius: 2rem;
    background-color: lightblue;
    animation-name: dot1;
    animation-duration: $dur;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    transform: translatex(-50px);
    
  }
  
  @keyframes dot1 {
    50% {
      transform: translatex(50px);
    }
  }
  
  .dot2 {
    width: 1rem;
    height: 1rem;
    border-radius: 3rem;
    opacity: $trans1;
    background-color: lightblue;
    animation-name: dot2;
    animation-duration: $dur;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    transform: translatey(-40px);
    
  }
  
  @keyframes dot2 {
    50% {
      transform: translatey(40px);
    }
  }
  
  .dot3 {
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 3rem;
    opacity: $trans2;
    background-color: lightblue;
    animation-name: dot3;
    animation-duration: $dur;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    transform: translatex(-80px);
    
  }
  
  @keyframes dot3 {
    50% {
      transform: translatex(80px);
    }
  }
  
  .dot4 {
    width: 0.3rem;
    height: 0.3rem;
    border-radius: 3rem;
    opacity: $trans2;
    background-color: lightblue;
    animation-name: dot4;
    animation-duration: $dur;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    transform: translate(-60px,50px);
    
  }
  
  @keyframes dot4 {
    50% {
      transform: translate(60px,-50px);
    }
  }
  
  .dot5 {
    width: 0.3rem;
    height: 0.3rem;
    border-radius: 3rem;
    opacity: $trans2;
    background-color: lightblue;
    animation-name: dot5;
    animation-duration: $dur;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    transform: translate(-70px,80px);
    
  }
  
  @keyframes dot5 {
    50% {
      transform: translate(70px,-80px);
    }
  }
.button {
  background-image: $gradient3;
  box-shadow: $shadow3;
  font-family: $font1;
  text-decoration: none;
  font-weight: 400;
  font-size: .9em;
  letter-spacing: 1px;
  color: $white;
  padding: 1rem 2em;
  border-radius: 12px;
  text-align: center;
  display: inline-block;
  transition-duration: 0.3s;
  margin: 0 auto;
  width: 30%;
  max-width: 18em;
  min-width: 13em;
  border-style: none;
    
}
.button:hover {
  transform:scale(1.07,1.07);
    
}

.button:active {
box-shadow: inset 0 2px 5px 0 rgba(0,0,0,0.30);
}

  footer {
    padding: 25px;
    // font-size: 12px;
    background-image: linear-gradient(225deg, #A0A9B2 0%, $grey 100%);
    color: $white;
    height: 20rem;
  }
  
.footer-content {
  display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 940px;
    margin: 0 auto;
}

.footer-nav {
  list-style: none;
  float: left;
  margin: -0.1em 2em 0 0;
  text-align: right;
  color: $white;
  border-right: 1px solid;
  padding-right: 3em;
        }

  .footer-nav a:hover {
    border-bottom: 2px solid $prime;
    padding-bottom: 2px;
    transition: padding-bottom .3s;
    padding-left: initial;
    
  }    

  .footer-nav * {
    margin-bottom: .5rem;
    padding-top: .2rem;
    transform: translateY(2px);

  }

  footer span {
    color: $white;
    font-weight: 500;
  }
  #copyright {
    max-width: 940px;
    margin: 0 auto;
    font-size: .7rem;
    text-align: left;
  }
  .ext-links {
    display: flex;
    align-items: center;
  }
  
  footer p {
    padding-top: 2px;
  }
  
  .ext-links a {
  padding-left: 1.2rem;
  text-decoration: none;
  color: $white;
   }
  
  .ext-links img {
    transition: transform 0.2s, filter 0.2s ease-in-out;
    width: 2rem;
    transform: translateY(-3px);
  }
  
  .ext-links img:hover {
    filter:brightness(95%);
    transform: scale(1.2);
    
    }
  
    .email-list {
      text-align: left;
      display: inline-block;
      margin: 3.75rem 1.2em 3.75rem 0;
          
  }
  
  .email-list h6 {
    font-size: 1.3em;
    color: $white;
    font-weight: 500;
    margin: initial;
  }

  .email-list p {
    margin: 0 0 .8rem 0;
    // font-size: .9rem;
  }
  
  
  label {
    color: #7e8a9a;
      margin-bottom: 0.25em;
      // display: inline-block;
  }
  
#bd-email {
  color: $text-grey;
  height: 2.5rem;
  width: 18em;
  margin-bottom: .5rem;
  border-radius: 12px;
  border-style: none;
  padding-left: 1em;
}

.email-list .button {
  display: list-item;
  width: 20em;
  height: 2.5rem;
  max-width: initial;
  margin: initial;
  padding: initial;
}

  #buttondown {
    font-size: .7rem;
    text-decoration: none;
  }
  .rule {
    display: none;
    border: 1px solid $white;
    margin: 90px auto;
    max-width: 450px;
  }
  
  .mobile {
    display: none; 
   }

.gumroad-button {
  display: none;
}

  @media only screen and (max-width: 960px) {
  .cta-text {
    text-align: center;
    margin: 30px auto;
  }

  .group2 {
    transform: translate(-40px,30px) rotate(0deg);
  }

  @keyframes group2 {
    100% {
      transform: translate(-40px,30px) rotate(360deg);
    }
  }

}

@media only screen and (max-width: 800px) {
  .footer-content {
    flex-direction: column;
  }

  footer {
    height: initial;
  }

  .ext-links {
    transform: translateX(-12%);
  }

#copyright {
  margin: 3rem auto 0 auto;
  text-align: center;
}

.email-list {
  margin: 3.75rem auto;
  text-align: center;
}

}
  @media only screen and (max-width: 700px) {
    .logo {
      width: 3em;
      height: 3em;
      float: left;
      /* margin: 10px 0 0 20px; */
         }
    .nav a {
      margin: 1em 0em .3em 1.5em;
    }
  
  .desk {
    display: none;
    
   }

   .mobile {
     display: initial;
     width: 100vw;
      }
   
    .hero h2 {
      background-color: initial;
      border-radius: initial;
      box-shadow: initial;
      text-align: left;
      border-bottom: 2px solid $grey-blue;
      color: $text-grey;
      margin-top: 10px;
      padding: 0px;
                
    }

.circ-art {
  animation-name: group;
  animation-duration: $duration;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform: translatey(-90px) rotate(0deg);
}

@keyframes group {
  100% {
    transform: translatey(-90px) rotate(360deg);
  }
  }
   
    .hero {
      margin-top: 40px;
      margin-bottom: 20px;
    }
  
    .fullbleed img {
      width: 100vw;
      margin: 0 auto;
      border-radius: 0px;

    }
    
      
        .text {
        margin: 10px auto;
        width: 90vw;
      }
  
      .imgCapt {
        margin: 12px 25px;
      }
  
      .index-hero {
      padding-top: 10px;
      transform: translateY(100px);
      }
      .index-hero h1 {
      padding: 20px 0 10px 0;
      }
  
      .hero img {
        border-radius: 0px;
        
      }
  
  .gallery-mid {
    padding-bottom: 30px;
  }
  
  .gallery-mid img {
    max-width: 80vw;
  }
  
  .items {
    width: 150px;
    height: 100px;
  }
  
      .rule {
        width: 50vw;
        margin: 2rem auto;
      }
      .ext-links {
        display: flex;
        margin-top: 8px;
      }
  
      .ext-links a {
        padding: initial;
      }

      // .gradient.contact {
      //   height: 45vh;
      // }
 
      

      .vert img {
        width: 33vw;
    }   



.cta-text h6 {
  font-size: 1.1rem;
  margin: 0.7rem -2em;
  font-weight: 400;
}


.button {
  width: 50vw;
  min-width: 10em;
}

.social {
  display: flex;
  align-items: center;
  margin: 1rem auto;
}

.social img {
  width: 2.5rem;
  margin: 0 1rem;
}

.email-list {
  margin: 3.75rem auto 2rem auto;
}

.ext-links {
  transform: translateX(0%);
  display: flex;
    flex-flow: wrap;
    margin-top: 8px;
    margin: 1rem auto;
    font-size: 1.4rem;

}

.footer-nav {
  margin: 1rem auto 2.3rem auto;
  text-align: center;
  color: #ffffff;
  border: initial;
  padding: 0;
  width: 50vw;
}

#copyright {
  margin: 0rem auto 0 auto;
}

}

@media only screen and (max-width: 320px) {

body {
  font-size: .9rem;
}

.bold {
  font-size: .7rem;
  font-weight: 500;
}

.items {
  width: 130px;
  height: 86px;
}

}       
