
$white:	red;
$teal:		#A7DAC5;
$prime: $teal;
$teal-trans: rgba(167, 218, 197, 0.2);
$grey:		#5E6D7C;
$grey-blue: #729EB3;
$light-blue: #E8F8FF;
$trans-blue: rgba(167,185,218,0.40);
$trans-green: rgba(197,241,223,0.50);
$trans-grey: rgba(5,15,35,0.30);
$grey: #5E6D7C;
$text-grey: #666B7A;
$gradient:	linear-gradient(0deg, #D7F2FF 0%, #FFFFFF 100%);
$gradient2: linear-gradient(47deg, #C2DAE6 0%, #729EB3 100%);
$gradient3: linear-gradient(16deg, #5BA6CB 0%, #88D8B7 100%);
$gradient4: linear-gradient(54deg, #d7f2ffb5 0%, #FFFFFF 100%);
$font1:   'Work Sans', Helvectia, Arial, sans-serif;
$font2: 'Roboto Mono', Courier, monospace;
$shadow1: 0 10px 20px 0 rgba(167,185,218,0.40);
$shadow2: 0 10px 20px 0 rgba(5,15,35,0.30);
$shadow3: 0 2px 2px 0 rgba(17,64,87,0.10), 0 10px 20px 0 rgba(3,48,29,0.09);
$duration: 10s;
$trans1: 0.2;
$trans2: 0.3;
$group-dur: 20s;
$dur: 10s;


  

@mixin dropshadow ($color) {
    box-shadow: 0 10px 20px 0 $color;
   }